<template>
    <div class="analytics wrapper">
        <div class="analytics-list">
            <div class="analytics-item">
                <img
                    src="~@/assets/img/analytics/Frame_5474.png"
                    alt="Frame_5474"
                >
                <div class="analytics-item--content">
                    <div class="analytics-item--title">
                        1k+
                    </div>
                    <p>
                        Experts on the platform
                    </p>
                </div>
            </div>
            <div class="analytics-item">
                <img
                    src="~@/assets/img/analytics/Frame_5475.png"
                    alt="Frame_5474"
                >
                <div class="analytics-item--content">
                    <div class="analytics-item--title">
                        251k
                    </div>
                    <p>
                        Projects completed
                    </p>
                </div>
            </div>
            <div class="analytics-item">
                <img
                    src="~@/assets/img/analytics/Frame_5476.png"
                    alt="Frame_5474"
                >
                <div class="analytics-item--content">
                    <div class="analytics-item--title">
                        40k+
                    </div>
                    <p>
                        Clients
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TheAnalytics'
}
</script>

<style lang="scss">
.analytics {
    @media (max-width: 480px) {
        padding: 0;
    }
    &-list {
        background: #fff;
        display: flex;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    &-item {
        padding: 25px 20px;
        display: flex;
        font-size: 14px;
        flex-grow: 1;
        align-items: center;
        >img {
            width: 48px;
            margin-right: 18px;
        }
        &--content {
            flex-grow: 1;
            border-right: 1px solid #EAEAEA;
            @media (max-width: 768px) {
                border: none;
            }
        }
        &--title {
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
        }
        &:last-child {
            .analytics-item--content {
                border: none;
            }
        }
    }
}
</style>
