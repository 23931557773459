<template>
    <div class="writers-list-header">
        <div class="wrapper">
            <div class="writers-list-header--top">
                <div>
                    <breadcrumbs
                        :settings="settingsBreadcrumbs"
                    />
                    <div class="writers-list-header--guaranty-container">
                        <div class="writers-list-header--guaranty">
                            <img
                                src="~@/assets/img/svg/satisfaction.svg"
                                alt="satisfaction"
                            >
                            100% Satisfaction
                        </div>
                        <div class="writers-list-header--guaranty">
                            <img
                                src="~@/assets/img/svg/money.svg"
                                alt="satisfaction"
                            >
                            Money-back guarantee
                        </div>
                    </div>
                </div>
                <div
                    class="writers-list-header--top-img"
                />
            </div>
            <the-analytics />
        </div>
    </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import TheAnalytics from '@/components/common/TheAnalytics.vue';

export default {
    name: 'WritersListHeader',
    components: {
        Breadcrumbs,
        TheAnalytics
    },
    props: {
        settingsBreadcrumbs: {
            type: Object,
            required: true
        }
    }
}

</script>

<style lang="scss">
.writers-list-header {
    background: #F2F6FF;
    margin-bottom: 110px;
    @media (max-width: 768px) {
        margin-bottom: 180px;
    }
    .analytics {
        margin-top: -50px;
        transform: translateY(50px);
        @media (max-width: 768px) {
            margin-top: -150px;
            transform: translateY(150px);
        }
    }
    &--top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
        &-img {
            max-width: 100%;
            background: url('~@/assets/img/writers-list-img.png') bottom / contain no-repeat;
            @media (max-width: 1024px) {
                display: none;
            }
        }
    }
    &--guaranty {
        &-container {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin: 20px 0 40px;
            @media (max-width: 1200px) {
                padding: 0 15px;
            }
            @media (max-width: 480px) {
                padding: 0;
                margin: 0 0 40px;
            }
        }
        padding: 10px;
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        background: #fff;
        border-radius: 4px;
        gap: 12px;
        box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 2px 6px -2px rgba(16, 24, 40, 0.03);
    }
}
</style>
