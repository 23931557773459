<template>
    <div class="page_writers">
        <writers-list-header
            :settings-breadcrumbs="settingsBreadcrumbs"
        />
        <div class="wrapper">
            <div class="page_writers--content">
                <div class="writer_list">
                    <div class="writer_list__header">
                        <p class="writer_list__header_title">
                            {{ writersCount | number }} candidates
                        </p>
                        <div class="writers_list__filter">
                            <div class="writers_list__filter-sort">
                                Sort by
                            </div>
                            <sorting
                                :sort-by-fields="sortByFields"
                                :search-object="searchObject"
                                :sort-order-fields="sortOrderFields"
                                class="express-order__filter"
                                @updateSorting="onActionUpdateFilter"
                            />
                            <button
                                class="express-order__online-select"
                                :class="{ active: searchObject.preset === 'Writers_Online' }"
                                @click="setPreset('Writers_Online')"
                            >
                                Online
                            </button>
                        </div>
                    </div>
                    <radius-loader v-if="isLoading && !writersList.length" />
                    <template
                        v-for="(writer, index) in writersList"
                        v-else
                    >
                        <WritersCard
                            :key="writer.id"
                            :writer="writer"
                            :index="index"
                            :skeleton="skeleton"
                            @openModalReview="openModalReview"
                        />
                    </template>
                    <div class="writer_list--load">
                        <custom-button
                            v-if="haveNextPage"
                            default
                            class="button btn-base_colored sort-button"
                            :loading="isLoading"
                            @on-btn-click="loadMore"
                        >
                            Load more
                        </custom-button>
                    </div>

                    <no-result
                        v-if="!isLoading && writersList.length === 0"
                        type="writers"
                    />
                </div>
                <div class="writers_sidebar">
                    <support-banner />
                </div>
            </div>
        </div>
        <our-guarantees />
        <review-modal ref="review-modal" />
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import Api from '@/helpers/api/index.js'
import filtersMixin from '@/mixins/filtersMixin'
import WritersCard from '@/components/writers/WritersCard.vue'
import WritersListHeader from '@/components/writers/WritersListHeader.vue'
import SupportBanner from '@/components/common/banners/SupportBanner.vue'
import OurGuarantees from '@/components/common/OurGuarantees.vue'
import ReviewModal from '@/components/modals/ReviewModal';
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import Sorting from '@/components/common/Sorting.vue'
import RadiusLoader from '@/components/common/RadiusLoader.vue'
import NoResult from '@/components/common/NoResult'

export default {
    components: {
        WritersCard,
        WritersListHeader,
        SupportBanner,
        OurGuarantees,
        ReviewModal,
        Sorting,
        RadiusLoader,
        NoResult
    },
    mixins: [
        filtersMixin
    ],
    metaInfo: {
        title: 'Our Writers'
    },
    data() {
        return {
            savedScroll: { x: 0, y: 0 },
            searchObject: {
                page: 1,
                sort_by: 'last_seen',
                sort_order: 'DESC',
                per_page: 5,
                preset: ''
            },
            writersList: [],
            writersCount: 0,
            haveNextPage: false,
            isLoading: false,
            isFirstLoad: true,
            skeleton: false
        }
    },
    computed: {
        settingsBreadcrumbs() {
            const { breadcrumbs } = this.$route.meta
            return {
                title: `
                    Hire best <span class="bc-writers--verified">verified</span>
                    <br />
                    <span class="bc-writers--subtitle">experts with reviews</span>
                `,
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Writers',
                        url: '/writers'
                    }
                ],
                excerpt: breadcrumbs.excerpt,
                additionalClass: breadcrumbs.additionalClass
            }
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        sortByFields() {
            return [
                {
                    text: 'Last seen',
                    value: 'last_seen'
                },
                {
                    text: 'Preferred writer',
                    value: 'preferred_count'
                },
                {
                    text: 'Success rate (Recent)',
                    value: 'rank_thirty_days'
                },
                {
                    text: 'Success rate (All Time)',
                    value: 'rank_all'
                },
                {
                    text: 'Projects',
                    value: 'orders_total'
                },
                {
                    text: 'Reviews',
                    value: 'rank_all_count'
                }
            ]
        },
        sortOrderFields() {
            return [
                {
                    text: 'ASC',
                    value: 'ASC'
                },
                {
                    text: 'DESC',
                    value: 'DESC'
                }
            ]
        },
        requestUrl() {
            return this.searchObject.preset ? '/api/writer/list-by-preset' : '/api/writer/list-by-params'
        }
    },
    async activated() {
        this.$nextTick(() => {
            window.scroll(0, this.savedScroll.y);
        });
        if (!this.isFirstLoad) {
            await this.updateList()
        }
    },
    async created() {
        this.searchObject = {
            ...this.searchObject,
            ...this.queryObject,
            page: 1
        }
        await this.getWriters()
    },
    beforeRouteLeave(to, from, next) {
        const scrollOffset = { y: window.pageYOffset || document.documentElement.scrollTop, x: 0 }
        this.savedScroll = { ...scrollOffset }
        next()
    },
    methods: {
        async onActionUpdateFilter(payload) {
            if (!this.isFirstLoad) {
                this.searchObject = {
                    ...this.searchObject,
                    page: 1,
                    ...payload
                }
                this.skeleton = true
                await this.getWriters(true, true)
                this.skeleton = false
            }
        },
        async getWriters(withCount = true, clear = false) {
            try {
                this.isLoading = true
                let requests = []
                requests = [...requests, Api.get(this.requestUrl, removeEmptyParamsFromRequest({ mode: 'list', ...this.searchObject }))]
                if (withCount) {
                    requests = [...requests, Api.get(this.requestUrl, removeEmptyParamsFromRequest({ mode: 'count', ...this.searchObject }))]
                }
                const responses = await Promise.all(requests)
                const { data } = responses[0]
                if (responses[1]) {
                    const { data: totals } = responses[1]
                    this.writersCount = totals.count
                }
                if (clear) {
                    this.writersList = []
                }
                this.writersList = [...this.writersList, ...data.data]
                this.haveNextPage = Boolean(data.next_page_url)
                if (JSON.stringify(this.searchObject) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: removeEmptyParamsFromRequest({ ...this.searchObject }) })
                }
                this.isFirstLoad = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        async loadMore() {
            if (!this.isFirstLoad) {
                this.isLoading = true
                this.searchObject.page += 1
                await this.getWriters(false)
            }
        },
        openModalReview(writerId) {
            this.$refs['review-modal'].open(writerId)
        },
        async syncWritersListKeepAlive(paylaod) {
            const { data } = await Api.get(this.requestUrl, { ...paylaod, mode: 'list' })
            return data.data
        },
        async updateList() {
            const requests = []
            const pageLoaded = this.searchObject.page
            for (let index = 1; index <= pageLoaded; index += 1) {
                requests.push(this.syncWritersListKeepAlive(
                    removeEmptyParamsFromRequest({ ...this.searchObject, page: index, per_page: this.searchObject.per_page || 10 })
                ))
            }
            const resp = await Promise.all(requests)
            this.writersList = this.writersList.map((writer) => {
                const find = resp.flat().find((item) => item.id === writer.id)
                if (!find) {
                    return false
                }
                writer = {
                    ...writer,
                    ...find
                }
                return writer
            }).filter((a) => Boolean(a))
        },
        async setPreset(preset) {
            if (this.searchObject.preset === preset) {
                this.searchObject.preset = ''
                this.onActionUpdateFilter()
                return
            }
            await this.onActionUpdateFilter({ preset })
        }
    }
}
</script>

<style lang="scss">
    .writer_list{
        border-radius: 10px;
        padding-bottom: 0;
        &--load{
            margin-top: 30px;
            display: flex;
            justify-content: center;
        }
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
            @media (max-width: 680px) {
                flex-direction: column;
                align-items: initial;
                gap: 10px;
            }
            &_title{
                font-weight: 700;
                font-size: 26px;
            }
        }
    }
    .page_writers{
        &--content {
            display: grid;
            grid-template-columns: 3fr 1fr;
            gap: 20px;
            @media (max-width:1200px) {
                grid-template-columns: 1fr;
            }
        }
        .writers_sidebar>.support-banner {
            position: sticky;
            top: 90px;
            @media all and (max-width: 1200px) {
                margin: 0 auto;
            }
        }
        .our_guarantees {
            margin-top: 30px;
        }
        .sort-filter{
            margin-top: 0;
            @media all and (max-width: 1200px) {
                margin-top: 0;
            }
            @media all and (max-width: 768px) {
                margin-top: 0;
            }
            @media all and (max-width: 576px) {
                top: 0;
                margin-bottom: 20px;
                margin-top: 0;
                &_panel {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                }
                &-title{
                    display: block;
                    width: 100%;
                    text-align: center;
                }
                &-link{
                    flex: 1;
                    white-space: nowrap;
                }
            }
        }
    }
    .writers_list{
        &__filter {
            display: flex;
            align-items: center;
            &-sort {
                margin: 0 10px;
                padding: 0 10px;
                font-weight: 600;
                @media (max-width: 680px) {
                    margin: 0 20px 0 auto;
                    padding: 0;
                }
                @media (max-width: 480px) {
                    display: none;
                }
            }
            .form-select {
                max-width: 180px;
            }
        }
    }
</style>
